const theme = {
  colors: {
    navbarBackgroundColor: "#d62828",
    navbarTextAndHamburgerMenuBackgroundColor: "#fcbf49",
    navbarTabColor: "#f77f00",
    textColor: "black",
    backgroundColor: "lightgray",
    contentBackgroundColor: "#eae2b7",
    overlayBackgroundColor: "#f7f1f185",
    popupBackground: "rgba(255, 255, 255, 0.25)",
  },
  boxShadow: {
    default: "10px 10px 34px -11px rgba(0, 0, 0, 0.75)",
  },
  fonts: {
    primary: "'Fredoka One', sans-serif",
    secondary: "'Arial Rounded MT Bold', sans-serif",
  },
  margins: {
    marginTop: "7px",
    spaceBetweenLi: "7px",
  },
};

export default theme;
